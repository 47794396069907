/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:c6dae16a-ba05-4fc5-8e31-bd382946a4fb",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ozxPaMDf7",
    "aws_user_pools_web_client_id": "tenfa3oluptd3dumclontfhfp",
    "oauth": {},
    "aws_user_files_s3_bucket": "newseefoodapp71c748a4eb6b4fbdacafa9ce432ad621124847-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "predictions": {
        "identify": {
            "identifyLabels": {
                "proxy": false,
                "region": "us-east-1",
                "defaults": {
                    "type": "LABELS"
                }
            }
        }
    }
};


export default awsmobile;
